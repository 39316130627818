<script>
import ApiService from "@services/api.service";

export default {
  name: "AttendanceReport",
  data() {
    return {
      loader: {
        page: false,
        button: false
      },
      groupOpts: [
        { key: "expiredDate", desc: "Due Date" },
        { key: "priority", desc: "Leads Priorities" },
        { key: "leadsType.description", desc: "Leads Types" },
        { key: "leadsType.leadsSource", desc: "Leads Sources" },
        { key: "status", desc: "Status" }
      ],
      parameter: {
        hierarchyList: []
      },
      filter: {
        dateFrom: null,
        dateTo: null,
        hierarchyCode: null,
        nik: null
      }
    };
  },
  computed: {},
  mounted() {
    this.loadHiearchy();
  },
  methods: {
    loadHiearchy() {
      this.changeLoader(true);
      ApiService.get("Hierarchy")
        .then(resp => {
          this.parameter.hierarchyList = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    generate() {
      this.loader.button = true;
      ApiService.downloadPost("/Report/attendance", this.filter)
        .then(resp => {
          this.download(resp.data, "AttendanceReport.xlsx");
        })
        .catch(err => {
          this.showToast("error", err.message);
        })
        .finally(() => {
          this.loader.button = false;
        });
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-overlay :show="loader.button">
        <b-form @submit.stop.prevent="generate">
          <b-row>
            <b-col md="6">
              <b-form-group label="Start Date">
                <b-form-datepicker v-model="filter.dateFrom" reset-button></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="End Date">
                <b-form-datepicker v-model="filter.dateTo" reset-button></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Hierarchy">
                <v-select
                  v-if="parameter.hierarchyList.length > 0"
                  v-model="filter.hierarchyCode"
                  label="description"
                  :reduce="x => x.code"
                  :options="parameter.hierarchyList"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="NIK">
                <b-form-input v-model="filter.nik"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-btn class="mx-auto" type="submit" variant="primary">Generate Excel File</b-btn>
        </b-form>
      </b-overlay>
    </b-card>
  </div>
</template>
